import moment from 'moment';

import { store } from '@/index';

import { prefixZeroFormat } from './numberUtils';

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  let dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 1) {
    dates.push(currDate.clone());
  }

  return dates;
};

export const dateToDay = (date) => {
  return moment(date).format('dddd');
};

export const momentToDuration = (dur) => {
  var hours = Math.floor(dur.asHours());
  var mins = Math.floor(dur.asMinutes()) - hours * 60;
  var sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;

  return prefixZeroFormat(hours) + ':' + prefixZeroFormat(mins) + ':' + (sec > 9 ? sec : '0' + sec);
};

export const momentToDurationTripCard = (dur) => {
  if (dur) {
    var hours = Math.floor(dur.asHours());
    var mins = Math.floor(dur.asMinutes()) - hours * 60;

    return prefixZeroFormat(hours) + 'h' + prefixZeroFormat(mins) + 'm';
  }
  return prefixZeroFormat(0) + 'h' + prefixZeroFormat(0) + 'm';
};

export const getAllDaysOfMonth = (currentDate) => {
  let monthIndex = parseInt(moment(currentDate).format('M')) - 1; // 0..11 instead of 1..12
  let date = new Date(parseInt(moment(currentDate).format('Y')), monthIndex, 1);
  let dates = [];
  while (date.getMonth() === monthIndex) {
    dates.push(moment(date).format());
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export const getAllDaysOfWeek = (currentDate) => {
  let current = currentDate.clone().startOf('isoWeek').toDate();
  let week = [];
  // Starting Monday not Sunday
  current.setDate(current.getDate() - current.getDay() + 1);
  for (let i = 0; i < 7; i++) {
    week.push(moment(new Date(current)).format());
    current.setDate(current.getDate() + 1);
  }
  return week;
};

export const hoursOfDay = (currentDate) => {
  let hours = [];
  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment(`${currentDate}${hour}:00:00`, 'DD/MM/YYYY[T]HH:mm:ss'));
  }
  return hours;
};

export const navigateToActiveDate = () => {
  const section = document.querySelector('.header-lane__content-box.active');
  section &&
    section.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'center',
    });
};

export const getShortMonthName = (monthNumber) => {
  return moment(monthNumber, 'M').format('MMMM').slice(0, 3);
};

export const getFullMonthName = (monthNumber) => {
  return moment(monthNumber, 'M').format('MMMM');
};

/*
   @description default date format
 */
export const defaultDateFormat = (date, dateFormat) => {
  if (!date) return null;

  return moment(date).format(dateFormat);
};
export const defaultDateTimeFormat = (date, dateFormat) => {
  dateFormat = dateFormat || store.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY';
  if (!date) return null;

  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;
  return moment(date).format(dateTimeFormat);
};

export const defaultTimeDateFormat = (date, dateFormat) => {
  dateFormat = dateFormat || store.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY';
  if (!date) return null;

  const timeFormat = 'HH:mm';
  const dateTimeFormat = `${timeFormat} ${dateFormat}`;
  return moment(date).format(dateTimeFormat);
};
