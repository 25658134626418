import { appConstants } from '@constants/app.constants';

const initialState = {
  isFetchingApp: false,
  formApp: null,
  app: null,

  isFetchingApps: false,
  isFetchingAppsFrom: 0,
  appsTotalResults: 0,
  appsLoaded: 0,
  apps: [],

  isFetchingAppVersion: false,
  formAppVersion: null,
  appVersion: null,

  isFetchingAppVersions: false,
  isFetchingAppVersionsFrom: 0,
  appVersionsTotalResults: 0,
  appVersionsLoaded: 0,
  appVersions: [],
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case appConstants.APP_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case appConstants.GET_APP_STARTED:
      return { ...state, isFetchingApp: true };
    case appConstants.GET_APP_FAILURE:
      return { ...state, isFetchingApp: false, app: null, error: action.message || '' };
    case appConstants.GET_APP_SUCCESS:
      return {
        ...state,
        isFetchingApp: false,
        app: action.app,
      };

    case appConstants.GET_APPS_STARTED:
      return { ...state, isFetchingApps: true, isFetchingAppsFrom: action.from || 0 };
    case appConstants.GET_APPS_FAILURE:
      return { ...state, isFetchingApps: false, apps: [], error: action.message || '' };
    case appConstants.GET_APPS_SUCCESS:
      return {
        ...state,
        isFetchingApps: false,
        apps: action.from > 0 ? [...state.apps, ...action.apps] : action.apps,
        appsLoaded: action.from > 0 ? state.apps.length + action.apps.length : action.apps.length,
        appsTotalResults: action.totalResults || 0,
      };

    case appConstants.SEARCH_APPS_STARTED:
      return { ...state, isFetchingApps: true, isFetchingAppsFrom: action.from || 0 };
    case appConstants.SEARCH_APPS_FAILURE:
      return { ...state, isFetchingApps: false, apps: [], error: action.message || '' };
    case appConstants.SEARCH_APPS_SUCCESS:
      return {
        ...state,
        isFetchingApps: false,
        apps: action.from > 0 ? [...state.apps, ...action.apps] : action.apps,
        appsLoaded: action.from > 0 ? state.apps.length + action.apps.length : action.apps.length,
        appsTotalResults: action.totalResults || 0,
      };

    case appConstants.CREATING_APP_STARTED:
      return { ...state, isFetchingApp: true };
    case appConstants.CREATING_APP_FAILURE:
      return { ...state, isFetchingApp: false, app: null, error: action.message || '' };
    case appConstants.CREATING_APP_SUCCESS:
      return {
        ...state,
        isFetchingApp: false,
        app: action.app,
        apps: [action.app, ...state.apps],
      };

    case appConstants.UPDATING_APP_STARTED:
      return { ...state, isFetchingApp: true };
    case appConstants.UPDATING_APP_FAILURE:
      return { ...state, isFetchingApp: false, app: null, error: action.message || '' };
    case appConstants.UPDATING_APP_SUCCESS:
      return {
        ...state,
        isFetchingApp: false,
        app: action.app,
        apps: [...state.apps].map((app) => {
          if (action.app.id === app.id) {
            return action.app;
          }
          return app;
        }),
      };

    case appConstants.DELETE_APP_STARTED:
      return { ...state, isFetchingApp: true };
    case appConstants.DELETE_APP_FAILURE:
      return { ...state, isFetchingApp: false, app: null, message: action.message || '' };
    case appConstants.DELETE_APP_SUCCESS:
      return {
        ...state,
        isFetchingApp: false,
        app: action.app,
        apps: [...state.apps].filter((app) => action.app.id !== app.id),
      };

    case appConstants.GET_APPVERSION_STARTED:
      return { ...state, isFetchingAppVersion: true };
    case appConstants.GET_APPVERSION_FAILURE:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: null,
        error: action.message || '',
      };
    case appConstants.GET_APPVERSION_SUCCESS:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: action.appVersion,
      };

    case appConstants.GET_APPVERSIONS_STARTED:
      return { ...state, isFetchingAppVersions: true, isFetchingAppVersionsFrom: action.from || 0 };
    case appConstants.GET_APPVERSIONS_FAILURE:
      return {
        ...state,
        isFetchingAppVersions: false,
        appVersions: [],
        error: action.message || '',
      };
    case appConstants.GET_APPVERSIONS_SUCCESS:
      return {
        ...state,
        isFetchingAppVersions: false,
        appVersions:
          action.from > 0 ? [...state.appVersions, ...action.appVersions] : action.appVersions,
        appVersionsLoaded:
          action.from > 0
            ? state.appVersions.length + action.appVersions.length
            : action.appVersions.length,
        appVersionsTotalResults: action.totalResults || 0,
      };

    case appConstants.SEARCH_APPVERSIONS_STARTED:
      return { ...state, isFetchingAppVersions: true, isFetchingAppVersionsFrom: action.from || 0 };
    case appConstants.SEARCH_APPVERSIONS_FAILURE:
      return {
        ...state,
        isFetchingAppVersions: false,
        appVersions: [],
        error: action.message || '',
      };
    case appConstants.SEARCH_APPVERSIONS_SUCCESS:
      return {
        ...state,
        isFetchingAppVersions: false,
        appVersions:
          action.from > 0 ? [...state.appVersions, ...action.appVersions] : action.appVersions,
        appVersionsLoaded:
          action.from > 0
            ? state.appVersions.length + action.appVersions.length
            : action.appVersions.length,
        appVersionsTotalResults: action.totalResults || 0,
      };

    case appConstants.CREATING_APPVERSION_STARTED:
      return { ...state, isFetchingAppVersion: true };
    case appConstants.CREATING_APPVERSION_FAILURE:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: null,
        error: action.message || '',
      };
    case appConstants.CREATING_APPVERSION_SUCCESS:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: action.appVersion,
        app: { ...state.app, versions: [...state.app.versions, action.appVersion] },
        // appVersions: [action.appVersion, ...state.appVersions],
      };

    case appConstants.UPDATING_APPVERSION_STARTED:
      return { ...state, isFetchingAppVersion: true };
    case appConstants.UPDATING_APPVERSION_FAILURE:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: null,
        error: action.message || '',
      };
    case appConstants.UPDATING_APPVERSION_SUCCESS:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: action.appVersion,
        appVersions: [...state.appVersions].map((appVersion) => {
          if (action.appVersion.id === appVersion.id) {
            return action.appVersion;
          }
          return appVersion;
        }),
      };

    case appConstants.DELETE_APPVERSION_STARTED:
      return { ...state, isFetchingAppVersion: true };
    case appConstants.DELETE_APPVERSION_FAILURE:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: null,
        message: action.message || '',
      };
    case appConstants.DELETE_APPVERSION_SUCCESS:
      return {
        ...state,
        isFetchingAppVersion: false,
        appVersion: action.appVersion,
        appVersions: [...state.appVersions].filter(
          (appVersion) => action.appVersion.id !== appVersion.id
        ),
      };
    default:
      return state;
  }
}
