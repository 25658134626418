import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/consignment/${id}`);
};

export const getExtended = (id = '') => {
  return api.get(`/consignment:extended/${id}`);
};

export const create = (consignment) => {
  return api.post(`/consignment`, consignment);
};

export const update = (consignment) => {
  return api.put(`/consignment`, consignment);
};

export const remove = (consignment) => {
  return api.delete(`/consignment/${consignment.id}`);
};

// Fetch and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/consignments/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/consignments/${from}/${amount}`, searchParameters);
};

export const latestExtended = (from = 0, amount = 20) => {
  return api.get(`/consignments:extended/${from}/${amount}`);
};

export const searchExtended = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/consignments:extended/${from}/${amount}`, searchParameters);
};

// business logic

export const updateConsignmentAddDocuments = (consignment, documents) => {
  return api.put(`/consignment/${consignment.id}/documents`, { documents });
};
