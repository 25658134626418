import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import QuoteForm from '@components/administration/quote/crud/QuoteForm';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';

class QuoteFormContainer extends PureComponent {
  render() {
    const { dateFormat, ...props } = this.props;
    return <QuoteForm {...props} {...props.ownProps} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.administration,
    user: state.auth.user,
    businesses: state.business.businesses,
    currencies: state.platform.currencies,
    variables: state.settings.variables,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuote: (id) => dispatch(administrationActions.getQuote(id)),
    getQuoteFromDocuments: (documents) =>
      dispatch(administrationActions.getQuoteFromDocuments(documents)),
    createQuote: (quote) => dispatch(administrationActions.createQuote(quote)),
    updateQuote: (quote) => dispatch(administrationActions.updateQuote(quote)),

    changeValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(QuoteFormContainer));
