import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';

import ContactDetailsView from '@components//general/ContactDetailsView';
import ConsignmentView from '@components/consignment/ConsignmentView';
import CustomerView from '@components/customer/CustomerView';
import NotesView from '@components/note/NotesView';
import QuoteContainer from '@containers/administration/quote/QuoteContainer';
import QuoteFormContainer from '@containers/administration/quote/crud/QuoteFormContainer';
import MailContainer from '@containers/mail/MailContainer';
import CreateTenderContainer from '@containers/tender/crud/CreateTenderContainer';
import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import UpdateTransportOrderContainer from '@containers/transportOrder/crud/UpdateTransportOrderContainer';

import ActionIcons from '@uicomponents/ActionIcons';
import Collapsible from '@uicomponents/Collapsible';
import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import LedgerTable from '@uicomponents/Ledger';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

import Alert from '@models/Alert';

import { getGoodsActions } from '@utils/consignmentUtils';
import { containsPermissionPart } from '@utils/userUtils';

import CMRPrint from './CMRPrint';
import { TransportOrderPDFDownload } from './TransportOrderPDF';

const DocumentsContainer = lazy(() => import('@containers/document/DocumentsContainer'));

class TransportOrderView extends Component {
  constructor(props) {
    super(props);
    this.cmrFormRefs = [];

    this.state = {
      filePreviewOpen: false,
      fileList: [],
      fileFocus: 0,
      tab: 'transportOrder',
      editMode: false,
    };
  }

  addCMRFormRef = () => {
    const ref = React.createRef();
    this.cmrFormRefs.push(ref);
    return ref;
  };

  componentDidMount() {
    const { transportOrder, updateTopStack, getLedgerEntriesByEntity } = this.props;

    getLedgerEntriesByEntity?.({ entityType: 'transportOrder', entityId: transportOrder?.id });

    if (transportOrder?.status === 'concept') {
      updateTopStack({ fullWidth: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { updateTopStack, transportOrder } = this.props;
    if (
      prevProps.transportOrder?.status !== transportOrder?.status &&
      transportOrder.status === 'concept'
    ) {
      updateTopStack({ fullWidth: true });
    } else if (
      prevProps.transportOrder?.status !== transportOrder?.status &&
      prevProps.transportOrder?.status === 'concept'
    ) {
      updateTopStack({ fullWidth: false });
    }
  }

  componentWillUnmount() {
    this.cmrFormRefs.forEach((ref) => (ref.current = null));
  }

  handlePrint = () => {
    const contents = this.cmrFormRefs
      .map((ref) => (ref.current ? `<div class="print-page">${ref.current?.innerHTML}</div>` : ''))
      .join('');
    const newWindow = window.open('', '_blank');

    newWindow.document.body.innerHTML = ` <html>
      <head>
        <style>
          /* Optional: Customize the print layout */
          @page {
            size: A4;
          }
          .print-page {
            page-break-after: always;
            background: url('/images/CMR.jpg');
            background-size: cover;
            position: relative !important;
            background-position: center;
            font-family: "Roboto", Helvetica, Arial, sans-serif;
            font-size: 10px;
            width: 210mm;
            height: 297mm;
            margin: 0;
          }
          .print-page:last-child {
            page-break-after: auto;
          }
          @media print {
            body {
              -webkit-print-color-adjust: exact; /* Chrome, Safari */
              color-adjust: exact; /* Firefox */
            }
            .print-page {
              page-break-after: always;
              background: url('/images/CMR.jpg') !important;
              background-size: cover !important;
              font-family: "Roboto", Helvetica, Arial, sans-serif !important;
              font-size: 10px !important;
              background-position: center !important;
              width: 210mm !important;
              position: relative !important;
              height: 297mm !important;
            }
            .print-page:last-child {
              page-break-after: auto;
            }
          }
        </style>
      </head>
      <body>
        <div class="print-container">
          ${contents}
        </div>
      </body>
    </html>`;

    newWindow.document.close(); // Close the document stream
    newWindow.onload = function () {
      newWindow.print();
      newWindow.onafterprint = function (event) {
        newWindow.close(); // Close the window after printing
      };
    };
  };

  renderDocuments = (documents) => {
    const { t } = this.props;
    return (
      <>
        <b>{t('transportOrder.attachments')}</b>
        <div className="files" style={{ flexWrap: 'wrap' }}>
          {documents.length > 0 ? (
            documents.map((attachment) => (
              <div
                key={`attachment-${attachment.id}`}
                className="file"
                onClick={() =>
                  this.setState({
                    filePreviewOpen: true,
                    files: documents.map((doc) => doc.entity.file),
                    focussedFile: attachment.entity.file,
                  })
                }
              >
                <FilePreview
                  file={attachment.entity.file}
                  isDocVerified={attachment.entity?.verified}
                />
              </div>
            ))
          ) : (
            <NoData className="left">{t('transportOrder.attachments.notFound')}</NoData>
          )}
        </div>
      </>
    );
  };

  renderCollapsibleSections = () => {
    const { transportOrder, t, addToStack } = this.props;
    return (
      <>
        <Collapsible
          name={`${t('notes.header')} ${
            transportOrder.notes.length ? `(${transportOrder.notes.length})` : ''
          }`}
          className="collapsible-element"
          defaultIsOpen={false}
        >
          <NotesView notes={transportOrder.notes} />
        </Collapsible>
        <Collapsible
          name={`${t('contactDetails.header')} ${
            transportOrder.contactDetails.length ? `(${transportOrder.contactDetails.length})` : ''
          }`}
          className="collapsible-element"
          defaultIsOpen={false}
        >
          <ContactDetailsView contactDetails={transportOrder.contactDetails} />
        </Collapsible>
      </>
    );
  };

  renderConsignments = () => {
    const { transportOrder, animationHasEnded, t } = this.props;
    return (
      <div className="consignments">
        {transportOrder.consignments.map((association, index) => {
          const consignment = association.entity;
          const allSortedActions = getGoodsActions(consignment).sort(
            (a, b) => a.entity.sequenceNr - b.entity.sequenceNr
          );
          return (
            <Collapsible
              key={`consignment-${consignment.id}`}
              name={`${t('consignment.header', { count: index + 1 })} ${
                consignment.name ? ` - ${consignment.name}` : ''
              }`}
              className="collapsible-element"
              defaultIsOpen={true}
              childStyle={{ padding: '10px 10px 10px 40px' }}
            >
              <CMRPrint
                key={`consignment-cmr-${index}`}
                consignment={consignment}
                firstAction={allSortedActions[0]}
                lastAction={allSortedActions[allSortedActions.length - 1]}
                customer={transportOrder.customer}
                formRef={this.addCMRFormRef}
                {...this.props}
              />
              <ConsignmentView
                {...this.props}
                key={consignment.id}
                simplifiedView={true}
                consignment={consignment}
                animationHasEnded={animationHasEnded}
              />
            </Collapsible>
          );
        })}
      </div>
    );
  };

  renderOrderDetails = () => {
    const { t, addToStack, transportOrder } = this.props;

    return (
      <>
        <div className="description">
          <b>{t('transportOrder.description')}</b>:{' '}
          {transportOrder.description ? (
            <div>{transportOrder.description}</div>
          ) : (
            <NoData className="left">{t('transportOrder.description.notFound')}</NoData>
          )}
        </div>
        <div className="description">
          <b>{t('transportOrder.referenceDescription')}</b>: {transportOrder.referenceDescription}
        </div>
        {transportOrder.mailMessage && (
          <div className="mail">
            <b>{t('transportOrder.fromMail')}</b>:{' '}
            <span
              className="as-link"
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: transportOrder.mailMessage.subject,
                  component: <MailContainer mail={transportOrder.mailMessage} />,
                });
              }}
            >
              {transportOrder.mailMessage.subject}
            </span>
          </div>
        )}
        {transportOrder.quote && (
          <div className="quote">
            <b>{t('transportOrder.quote')}</b>:{' '}
            <span
              className="as-link"
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: transportOrder.quote.name,
                  component: <QuoteContainer quoteId={transportOrder.quote.id} />,
                });
              }}
            >
              {transportOrder.quote.name}
            </span>
          </div>
        )}
        <div className="deliveryTerms">
          <b>{t('transportOrder.deliveryTerms')}</b>:{' '}
          {t(`deliveryTerms.${transportOrder.deliveryTerms}`)}
        </div>
        <div className="client">
          <b>{t('transportOrder.customer')}</b>:{` `}
          <CustomerView
            {...this.props}
            customer={transportOrder.customer}
            addToStack={addToStack}
          />
        </div>
      </>
    );
  };

  renderBasicInfo = () => {
    const {
      t,
      transportOrder,
      onChange,
      isPublic,
      platform,
      addToStack,
      createAlert,
      cancelTransportOrder,
      deleteTransportOrder,
    } = this.props;

    return (
      <div className="information">
        <div className="flex-container justify-between no-wrap">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin with-flair">
                {transportOrder.name} <span>{transportOrder.status}</span>
              </h1>
            </div>
            <h3 className="no-margin">#{transportOrder.orderId}</h3>
          </div>
          <ActionIcons
            namespace={transportOrder?.name}
            icons={[
              {
                type: 'custom',
                icon: '/icons/CMR.svg',
                enabled: true,
                onClick: (e) => {
                  this.handlePrint();
                },
              },
              !isPublic &&
                platform?.features?.map((feature) => feature.name)?.includes('tenders') &&
                transportOrder.tender == null &&
                transportOrder.status === 'accepted' && {
                  type: 'custom',
                  icon: '/icons/marketplace.svg',
                  enabled: true,
                  onClick: (e) => {
                    addToStack({
                      name: t('tender.create.header'),
                      component: <CreateTenderContainer transportOrder={{ ...transportOrder }} />,
                    });
                  },
                },
              {
                type: 'pdfDownload',
                icon: '/icons/pdf.svg',
                enabled: true,
                onClick: (e) => {
                  document.querySelector('.transportOrder-view .pdf-download').click();
                },
                children: (
                  <TransportOrderPDFDownload
                    key={transportOrder.orderId}
                    transportOrder={{ ...transportOrder }}
                    platform={platform}
                    className="pdf-download"
                  />
                ),
              },
              {
                type: 'custom',
                icon: '/icons/link.svg',
                enabled: true,
                onClick: (e) => {
                  try {
                    navigator.clipboard.writeText(
                      `${
                        platform?.hostNames?.[0]?.name || window?.location?.origin
                      }/transportOrders/${transportOrder.id}/share/${
                        transportOrder?.shareSettings?.shareCode
                      }`
                    );

                    createAlert(
                      new Alert(
                        t('notification.copy.success.title'),
                        t('notification.copy.success.description'),
                        'success'
                      )
                    );
                  } catch (e) {
                    console.error(e);
                    createAlert(
                      new Alert(
                        t('notification.copy.error.title'),
                        t('notification.copy.error.description'),
                        'error'
                      )
                    );
                  }
                },
              },
              transportOrder.status !== 'concept' &&
                !isPublic && {
                  type: 'custom',
                  icon: '/icons/pencil.svg',
                  enabled: true,
                  onClick: (e) => {
                    addToStack({
                      name: t('transportOrder.update.header'),
                      component: (
                        <UpdateTransportOrderContainer
                          transportOrder={{ ...transportOrder }}
                          callback={(result) => {
                            onChange('transportOrder', result.transportOrder || {});
                          }}
                        />
                      ),
                    });
                  },
                },
              transportOrder.status === 'concept' &&
                !isPublic && {
                  type: 'custom',
                  icon: '/icons/pencil.svg',
                  enabled: true,
                  onClick: (e) => {
                    this.setState({ editMode: true });
                  },
                },
              !isPublic &&
                ['requested', 'accepted'].includes(transportOrder.status) && {
                  type: 'options',
                  enabled: true,
                  optionItems: [
                    {
                      name: t('form.cancel'),
                      onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        cancelTransportOrder(transportOrder);
                      },
                    },
                    {
                      name: t('form.delete'),
                      onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        deleteTransportOrder(transportOrder);
                      },
                    },
                  ],
                },
            ]}
          />
        </div>
      </div>
    );
  };

  renderLedger = () => {
    const { t, ledgerEntries } = this.props;
    return (
      <div className="ledger mt-10">
        <Collapsible
          name={`${t('ledger')}`}
          className="collapsible-element"
          defaultIsOpen={true}
          childStyle={{ padding: '10px 10px' }}
        >
          <LedgerTable t={t} data={ledgerEntries} />
        </Collapsible>
      </div>
    );
  };

  render() {
    const { tab, editMode } = this.state;
    const {
      t,
      platform,
      isPublic,
      onChange,
      addToStack,
      transportOrder,
      isFetchingTransportOrder,
      acceptTransportOrder,
      cancelTransportOrder,
      updateTransportOrder,
      ledgerEntries,
    } = this.props;

    const { filePreviewOpen, files, focussedFile } = this.state;
    if (isFetchingTransportOrder || !transportOrder) {
      return <Loader />;
    }

    return (
      <>
        {transportOrder.status === 'concept' ? (
          <div className="flex-container no-wrap">
            <div
              className="docs flex-container column no-wrap one p-10 rounded bg-white border-all"
              style={{ height: '100vh', overflowY: 'auto' }}
            >
              {transportOrder.documents.map((attachment) => (
                <div key={attachment.id} className="container filePreviewer-container mb-2 xl-box">
                  <iframe src={`${window.location.protocol}${attachment.entity.file.url}`} />
                </div>
              ))}
            </div>
            {editMode ? (
              <div
                className="bg-white rounded border-all d-flex justify-content-center"
                style={{ height: '100vh', overflowY: 'auto', flex: 1 }}
              >
                <UpdateTransportOrderContainer
                  preventPop={true}
                  onClose={() => {
                    this.setState({ editMode: false });
                  }}
                  transportOrder={{ ...transportOrder }}
                  callback={(result) => {
                    this.setState({ editMode: false });
                    onChange('transportOrder', result.transportOrder || {});
                  }}
                />
              </div>
            ) : (
              <div
                className="transportOrder-view border-all rounded bg-white one p-20"
                style={{ height: '100vh', flex: 1, overflowY: 'scroll' }}
              >
                <>
                  {this.renderBasicInfo()}
                  {this.renderOrderDetails()}
                  {this.renderDocuments(transportOrder.documents)}
                  {this.renderConsignments()}
                  {this.renderCollapsibleSections()}
                  {containsPermissionPart('ledger') && this.renderLedger()}

                  {/* Actions Bar */}
                  <div className="action-bar">
                    <button
                      className="light"
                      onClick={(e) => {
                        e.preventDefault();

                        cancelTransportOrder(transportOrder);
                      }}
                    >
                      {t('form.cancel')}
                    </button>
                    <div className="buttons">
                      <button
                        className="mr-2"
                        onClick={(e) => {
                          e.preventDefault();

                          const newTransportOrder = { ...transportOrder };
                          newTransportOrder.status = 'requested';

                          updateTransportOrder(newTransportOrder);
                        }}
                      >
                        {t('form.saveAsRequested')}
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();

                          acceptTransportOrder(transportOrder);
                        }}
                      >
                        {t('form.accept')}
                      </button>
                    </div>
                  </div>
                </>
              </div>
            )}
          </div>
        ) : (
          <div className="transportOrder-view step-form wide">
            <FileViewer
              t={t}
              isActive={filePreviewOpen}
              files={files}
              file={focussedFile}
              onClose={() => {
                this.setState({
                  files: [],
                  focussedFile: null,
                  filePreviewOpen: false,
                });
              }}
            />

            <ScrollContainer
              verticle={false}
              hideScrollbars={false}
              className="align-items-center d-flex gap-10 tab-links-container my-1"
            >
              <div
                className={`tab-links${tab === 'transportOrder' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'transportOrder' })}
              >
                {t('transportOrder.header')}
              </div>
              <div
                className={`tab-links ${tab === 'documents' ? 'tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'documents' })}
              >
                {t('documents.header')}
              </div>
              {!isPublic && (
                <>
                  {containsPermissionPart('ledger') && (
                    <div
                      className={`tab-links ${tab === 'ledger' ? 'tab-active' : ''}`}
                      onClick={() => this.setState({ tab: 'ledger' })}
                    >
                      {t('ledger')}
                    </div>
                  )}
                  {this.props.platform?.features?.filter((feature) => feature.name === 'todo')
                    .length > 0 && (
                    <div
                      className={`tab-links${tab === 'todos' ? ' tab-active' : ''}`}
                      onClick={() => this.setState({ tab: 'todos' })}
                    >
                      {t('todos.header')}
                    </div>
                  )}
                </>
              )}
            </ScrollContainer>

            <section className={`chauffeur-view__box activity${tab === 'todos' ? ' active' : ''}`}>
              {tab === 'todos' ? (
                <EntityTodosContainer
                  {...this.props}
                  relatedEntity={transportOrder}
                  relatedEntityType="transportOrder"
                />
              ) : null}
            </section>

            <section className={`chauffeur-view__box activity${tab === 'ledger' ? ' active' : ''}`}>
              {tab === 'ledger' && (
                <div className="ledger mt-10">
                  <LedgerTable t={t} data={ledgerEntries} />
                </div>
              )}
            </section>

            <section
              className={`chauffeur-view__box activity${tab === 'documents' ? ' active' : ''}`}
            >
              {tab === 'documents' && (
                <Suspense fallback={<>{<Loader />}</>}>
                  <DocumentsContainer
                    entity={transportOrder}
                    entityType="transportOrder"
                    onChange={(newTransportOrder) => {
                      const { updateTransportOrder } = this.props;

                      updateTransportOrder(newTransportOrder);
                    }}
                    callback={(newTransportOrder) => {
                      onChange('transportOrder', newTransportOrder || {});
                    }}
                  />
                </Suspense>
              )}
            </section>

            <section
              className={`chauffeur-view__box activity${tab === 'transportOrder' ? ' active' : ''}`}
            >
              {tab === 'transportOrder' ? (
                <>
                  {this.renderBasicInfo()}
                  {this.renderOrderDetails()}
                  {this.renderDocuments(transportOrder.documents)}
                  {this.renderConsignments()}
                  {this.renderCollapsibleSections()}

                  {/* Actions Bar */}
                  {transportOrder.status === 'requested' && !isPublic && (
                    <div className="action-bar">
                      <button
                        className="light"
                        onClick={(e) => {
                          e.preventDefault();

                          cancelTransportOrder(transportOrder);
                        }}
                      >
                        {t('form.cancel')}
                      </button>
                      <div className="buttons">
                        {!transportOrder.quote &&
                          platform?.features
                            ?.map((feature) => feature.name)
                            ?.includes('financial') && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();

                                addToStack({
                                  name: t('quote.create'),
                                  fullWidth: true,
                                  component: (
                                    <QuoteFormContainer
                                      administration={transportOrder.administration}
                                      targetBusiness={transportOrder.customer.business}
                                      transportOrder={transportOrder}
                                      callback={(e) => {
                                        getTransportOrder(transportOrder.id);
                                      }}
                                    />
                                  ),
                                });
                              }}
                            >
                              {t('quote.create')}
                            </button>
                          )}
                        {transportOrder.quote && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();

                              addToStack({
                                name: transportOrder.quote.name,
                                component: <QuoteFormContainer quote={transportOrder.quote} />,
                              });
                            }}
                          >
                            {t('quote.view')}
                          </button>
                        )}
                        <button
                          onClick={(e) => {
                            e.preventDefault();

                            acceptTransportOrder(transportOrder);
                          }}
                        >
                          {t('form.accept')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </section>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(TransportOrderView);
