export const administrationConstants = {
  ADMINISTRATION_CHANGE_VALUE: 'ADMINISTRATION_CHANGE_VALUE',

  GET_ADMINISTRATION_STARTED: 'GET_ADMINISTRATION_STARTED',
  GET_ADMINISTRATION_SUCCESS: 'GET_ADMINISTRATION_SUCCESS',
  GET_ADMINISTRATION_FAILURE: 'GET_ADMINISTRATION_FAILURE',

  GET_ADMINISTRATIONS_STARTED: 'GET_ADMINISTRATIONS_STARTED',
  GET_ADMINISTRATIONS_SUCCESS: 'GET_ADMINISTRATIONS_SUCCESS',
  GET_ADMINISTRATIONS_FAILURE: 'GET_ADMINISTRATIONS_FAILURE',

  SEARCH_ADMINISTRATIONS_STARTED: 'SEARCH_ADMINISTRATIONS_STARTED',
  SEARCH_ADMINISTRATIONS_SUCCESS: 'SEARCH_ADMINISTRATIONS_SUCCESS',
  SEARCH_ADMINISTRATIONS_FAILURE: 'SEARCH_ADMINISTRATIONS_FAILURE',

  CREATING_ADMINISTRATION_STARTED: 'CREATING_ADMINISTRATION_STARTED',
  CREATING_ADMINISTRATION_SUCCESS: 'CREATING_ADMINISTRATION_SUCCESS',
  CREATING_ADMINISTRATION_FAILURE: 'CREATING_ADMINISTRATION_FAILURE',

  UPDATING_ADMINISTRATION_STARTED: 'UPDATING_ADMINISTRATION_STARTED',
  UPDATING_ADMINISTRATION_SUCCESS: 'UPDATING_ADMINISTRATION_SUCCESS',
  UPDATING_ADMINISTRATION_FAILURE: 'UPDATING_ADMINISTRATION_FAILURE',

  DELETE_ADMINISTRATION_STARTED: 'DELETE_ADMINISTRATION_STARTED',
  DELETE_ADMINISTRATION_SUCCESS: 'DELETE_ADMINISTRATION_SUCCESS',
  DELETE_ADMINISTRATION_FAILURE: 'DELETE_ADMINISTRATION_FAILURE',

  GET_TRANSACTIONS_STARTED: 'GET_TRANSACTIONS_STARTED',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',

  GET_TRANSACTIONS_BY_DATE_STARTED: 'GET_TRANSACTIONS_BY_DATE_STARTED',
  GET_TRANSACTIONS_BY_DATE_SUCCESS: 'GET_TRANSACTIONS_BY_DATE_SUCCESS',
  GET_TRANSACTIONS_BY_DATE_FAILURE: 'GET_TRANSACTIONS_BY_DATE_FAILURE',

  CREATING_TRANSACTION_STARTED: 'CREATING_TRANSACTION_STARTED',
  CREATING_TRANSACTION_SUCCESS: 'CREATING_TRANSACTION_SUCCESS',
  CREATING_TRANSACTION_FAILURE: 'CREATING_TRANSACTION_FAILURE',

  UPDATING_TRANSACTION_STARTED: 'UPDATING_TRANSACTION_STARTED',
  UPDATING_TRANSACTION_SUCCESS: 'UPDATING_TRANSACTION_SUCCESS',
  UPDATING_TRANSACTION_FAILURE: 'UPDATING_TRANSACTION_FAILURE',

  CREATING_CHARGE_STARTED: 'CREATING_CHARGE_STARTED',
  CREATING_CHARGE_SUCCESS: 'CREATING_CHARGE_SUCCESS',
  CREATING_CHARGE_FAILURE: 'CREATING_CHARGE_FAILURE',

  UPDATING_CHARGE_STARTED: 'UPDATING_CHARGE_STARTED',
  UPDATING_CHARGE_SUCCESS: 'UPDATING_CHARGE_SUCCESS',
  UPDATING_CHARGE_FAILURE: 'UPDATING_CHARGE_FAILURE',

  EXECUTE_CHARGE_STARTED: 'EXECUTE_CHARGE_STARTED',
  EXECUTE_CHARGE_SUCCESS: 'EXECUTE_CHARGE_SUCCESS',
  EXECUTE_CHARGE_FAILURE: 'EXECUTE_CHARGE_FAILURE',

  GET_CURRENCIES_STARTED: 'GET_CURRENCIES_STARTED',
  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILURE: 'GET_CURRENCIES_FAILURE',

  GET_INVOICES_STARTED: 'GET_INVOICES_STARTED',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILURE: 'GET_INVOICES_FAILURE',

  GET_INVOICE_STARTED: 'GET_INVOICE_STARTED',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',
  GET_INVOICE_FROM_DOCUMENT_SUCCESS: 'GET_INVOICE_FROM_DOCUMENT_SUCCESS',

  CREATE_INVOICE_STARTED: 'CREATE_INVOICE_STARTED',
  CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  CREATE_INVOICE_FAILURE: 'CREATE_INVOICE_FAILURE',

  UPDATE_INVOICE_STARTED: 'UPDATE_INVOICE_STARTED',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_FAILURE: 'UPDATE_INVOICE_FAILURE',

  GET_QUOTES_STARTED: 'GET_QUOTES_STARTED',
  GET_QUOTES_SUCCESS: 'GET_QUOTES_SUCCESS',
  GET_QUOTES_FAILURE: 'GET_QUOTES_FAILURE',

  GET_QUOTE_STARTED: 'GET_QUOTE_STARTED',
  GET_QUOTE_SUCCESS: 'GET_QUOTE_SUCCESS',
  GET_QUOTE_FAILURE: 'GET_QUOTE_FAILURE',
  GET_QUOTE_FROM_DOCUMENT_SUCCESS: 'GET_QUOTE_FROM_DOCUMENT_SUCCESS',

  CREATE_QUOTE_STARTED: 'CREATE_QUOTE_STARTED',
  CREATE_QUOTE_SUCCESS: 'CREATE_QUOTE_SUCCESS',
  CREATE_QUOTE_FAILURE: 'CREATE_QUOTE_FAILURE',

  UPDATE_QUOTE_STARTED: 'UPDATE_QUOTE_STARTED',
  UPDATE_QUOTE_SUCCESS: 'UPDATE_QUOTE_SUCCESS',
  UPDATE_QUOTE_FAILURE: 'UPDATE_QUOTE_FAILURE',

  GET_PAYOUTS_STARTED: 'GET_PAYOUTS_STARTED',
  GET_PAYOUTS_SUCCESS: 'GET_PAYOUTS_SUCCESS',
  GET_PAYOUTS_FAILURE: 'GET_PAYOUTS_FAILURE',

  GET_PAYOUT_STARTED: 'GET_PAYOUT_STARTED',
  GET_PAYOUT_SUCCESS: 'GET_PAYOUT_SUCCESS',
  GET_PAYOUT_FAILURE: 'GET_PAYOUT_FAILURE',

  CREATING_PAYOUT_STARTED: 'CREATING_PAYOUT_STARTED',
  CREATING_PAYOUT_SUCCESS: 'CREATING_PAYOUT_SUCCESS',
  CREATING_PAYOUT_FAILURE: 'CREATING_PAYOUT_FAILURE',

  UPDATING_PAYOUT_STARTED: 'UPDATING_PAYOUT_STARTED',
  UPDATING_PAYOUT_SUCCESS: 'UPDATING_PAYOUT_SUCCESS',
  UPDATING_PAYOUT_FAILURE: 'UPDATING_PAYOUT_FAILURE',

  SET_FORM: 'SET_FORM',

  DELETE_INVOICE_STARTED: 'DELETE_INVOICE_STARTED',
  DELETE_INVOICE_SUCCESS: 'DELETE_INVOICE_SUCCESS',
  DELETE_INVOICE_FAILURE: 'DELETE_INVOICE_FAILURE',

  DELETE_QUOTE_STARTED: 'DELETE_QUOTE_STARTED',
  DELETE_QUOTE_SUCCESS: 'DELETE_QUOTE_SUCCESS',
  DELETE_QUOTE_FAILURE: 'DELETE_QUOTE_FAILURE',
};
