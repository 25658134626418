import React, { Component } from 'react';

import moment from 'moment';

import { defaultTimeDateFormat } from '@utils/dateUtils';
import { administrativeReferenceToString } from '@utils/locationUtils';

class CMRPrint extends Component {
  render() {
    const {
      formRef,
      customer,
      firstAction,
      lastAction,
      consignment,
      deliveryTerms,
      transportOrderDescription,
      transportOrderAdministration,
    } = this.props;

    return (
      <div>
        <div
          ref={formRef()}
          className="printArea"
          id="printArea"
          style={{
            display: 'none',
            background: 'url("/images/CMR.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '210mm',
            height: '297mm',
            position: 'relative',
            fontSize: '10px',
          }}
        >
          <div
            className="consignor"
            style={{
              position: 'absolute',
              top: '15mm',
              left: '15.88mm',
              width: '88mm',
              height: '19mm',
            }}
          >
            {consignment?.originalLegalSender ? (
              <>
                <b>{consignment?.originalLegalSender?.name}</b>
                {administrativeReferenceToString(
                  consignment?.originalLegalSender?.administrativeReference || {},
                  false,
                  false,
                  consignment?.originalLegalSender
                )}
              </>
            ) : (
              <>
                <b>{customer?.business?.name}</b>
                {administrativeReferenceToString(
                  customer?.business?.locations?.[0]?.entity?.administrativeReference || {},
                  false,
                  false,
                  customer?.business?.locations?.[0]?.entity
                )}
              </>
            )}
          </div>

          <div
            className="consignee"
            style={{
              position: 'absolute',
              top: '41mm',
              left: '15.88mm',
              width: '88mm',
              height: '19mm',
            }}
          >
            {consignment?.originalLegalAddressee
              ? administrativeReferenceToString(
                  consignment?.originalLegalAddressee?.administrativeReference || {},
                  true
                )
              : administrativeReferenceToString(
                  consignment?.lastAction?.location?.entity?.administrativeReference || {},
                  true
                )}
          </div>

          <div
            className="carrier"
            style={{
              position: 'absolute',
              top: '41mm',
              left: '107mm',
              width: '88mm',
              height: '19mm',
            }}
          >
            {transportOrderAdministration?.locations?.length > 0 &&
              administrativeReferenceToString(
                transportOrderAdministration?.locations[0]?.entity?.administrativeReference || {},
                true
              )}
          </div>

          <div
            className="deliveryPlace"
            style={{
              position: 'absolute',
              top: '66mm',
              left: '15.88mm',
              width: '88mm',
              height: '11mm',
            }}
          >
            {administrativeReferenceToString(
              consignment?.lastAction?.location?.entity?.administrativeReference || {},
              true
            )}
          </div>

          <div
            className="successiveCarriers"
            style={{
              position: 'absolute',
              top: '66mm',
              left: '107mm',
              width: '88mm',
              height: '11mm',
            }}
          >
            
            {transportOrderAdministration?.locations?.length > 0 &&
              administrativeReferenceToString(
                transportOrderAdministration?.locations[0]?.entity?.administrativeReference || {},
                true
              )}
          </div>

          <div
            className="goodsTakeoverPlace"
            style={{
              position: 'absolute',
              top: '83mm',
              left: '15.88mm',
              width: '88mm',
              height: '11mm',
            }}
          >
            {defaultTimeDateFormat(
              consignment?.actions?.[0]?.entity?.createdAt,
              this.props.dateFormat
            )}
          </div>

          <div
            className="documents"
            style={{
              position: 'absolute',
              top: '101mm',
              left: '15.88mm',
              width: '88mm',
              height: '11mm',
            }}
          >
            {consignment.documents
              .map((association) => association.entity)
              .map((document, index) => (
                <span>{document?.file?.originalName}&nbsp;</span>
              ))}
          </div>

          <div
            className="carrierReservations"
            style={{
              position: 'absolute',
              top: '83mm',
              left: '107mm',
              width: '88mm',
              height: '28mm',
            }}
          >
            {lastAction?.remark}
          </div>

          <div
            className="goodsDetails"
            style={{
              position: 'absolute',
              top: '118mm',
              left: '15.88mm',
              width: '180mm',
              height: '68mm',
            }}
          >
            {consignment.goods
              .map((association) => association.entity)
              .filter((good) => good._entity === 'Items')
              .map((good, index) => (
                <div key={`good-${index}`} style={{ display: 'flex' }}>
                  <span style={{ textAlign: 'center', width: '30mm' }}>{good?.description}</span>
                  <span style={{ textAlign: 'center', width: '24mm' }}>{good?.quantity}</span>
                  <span style={{ textAlign: 'center', width: '29mm' }}>
                    {good?.packagingMaterial}
                  </span>
                  <span style={{ textAlign: 'center', width: '30mm' }}>{good?.productType}</span>
                  <span style={{ textAlign: 'center', width: '22mm' }}>{good?.hsCode}</span>
                  <span style={{ textAlign: 'center', width: '22mm' }}>
                    {good?.grossWeight?.value}
                  </span>
                  <span style={{ textAlign: 'center', width: '22mm' }}>
                    {good?.length?.value * good?.height?.value * good?.width?.value}
                  </span>
                </div>
              ))}
          </div>

          <div
            className="senderInstructions"
            style={{
              position: 'absolute',
              top: '193mm',
              left: '15.88mm',
              width: '88mm',
              height: '38mm',
            }}
          >
            {transportOrderDescription}
          </div>

          <div
            className="specialAgreements"
            style={{
              position: 'absolute',
              top: '193mm',
              left: '107mm',
              width: '88mm',
              height: '21mm',
            }}
          >
            {consignment?.remark}
          </div>

          <div
            className="paymentDirections"
            style={{
              position: 'absolute',
              top: '237mm',
              left: '15.88mm',
              width: '88mm',
              height: '6.5mm',
            }}
          >
            {deliveryTerms}
          </div>

          <div
            className="establishedIn"
            style={{
              position: 'absolute',
              top: '250mm',
              left: '15.88mm',
              width: '88mm',
              height: '4.6mm',
            }}
          >
            {defaultTimeDateFormat(moment.now(), this.props.dateFormat)}
          </div>

          <div
            className="reimbursement"
            style={{
              position: 'absolute',
              top: '245mm',
              left: '125.2mm',
              width: '29.5mm',
              height: '4.7mm',
            }}
          ></div>

          <div
            className="loadArrival"
            style={{
              position: 'absolute',
              top: '222mm',
              left: '130mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {defaultTimeDateFormat(
              firstAction?.constraints?.find(
                (item) => item.entity.type === 'startDateTimeConstraint'
              )?.entity.value.startDateTime,
              this.props.dateFormat
            )}
          </div>
          <div
            className="loadDeparture"
            style={{
              position: 'absolute',
              top: '222mm',
              left: '152.5mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {defaultTimeDateFormat(
              firstAction?.endTime || 
              firstAction?.constraints?.find(
                (item) => item.entity.type === 'endDateTimeConstraint'
              )?.entity.value.endDateTime,
              this.props.dateFormat
            )}
          </div>
          <div
            className="loadTotalTime"
            style={{
              position: 'absolute',
              top: '222mm',
              left: '174.5mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {firstAction?.duration}
          </div>

          <div
            className="unloadArrival"
            style={{
              position: 'absolute',
              top: '234mm',
              left: '130mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {defaultTimeDateFormat(
              lastAction?.startTime ||
                lastAction?.constraints?.find(
                  (item) => item.entity.type === 'startDateTimeConstraint'
                )?.entity.value.startDateTime,
              this.props.dateFormat
            )}
          </div>
          <div
            className="unloadDeparture"
            style={{
              position: 'absolute',
              top: '234mm',
              left: '152.5mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {defaultTimeDateFormat(
              lastAction?.endTime || 
              lastAction?.constraints?.find(
                (item) => item.entity.type === 'endDateTimeConstraint'
              )?.entity.value.endDateTime,
              this.props.dateFormat
            )}
          </div>
          <div
            className="unloadTotalTime"
            style={{
              position: 'absolute',
              top: '234mm',
              left: '174.5mm',
              width: '21.5mm',
              height: '10.5mm',
            }}
          >
            {lastAction?.duration}
          </div>
        </div>
      </div>
    );
  }
}

export default CMRPrint;
