import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import draftToHtml from 'draftjs-to-html';

import CommentFormContainer from '@containers/todo/todo/CommentFormContainer';

import PopOver from '@uicomponents/PopOver';

import { defaultDateFormat } from '@utils/dateUtils';

export default class CommentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popOvers: [],

      editComment: false,
    };
  }

  mentionEntityToHTML = (entity, originalText) => {
    if (entity.type === 'mention') {
      const { name } = entity?.data?.mention;
      return `<span class="font-medium font-sm text-primary">@${name}</span>`;
    }
    return originalText;
  };

  render() {
    const { t, todo, user, comment, addComment, deleteComment, closeAddComment } = this.props;
    const { editComment } = this.state;

    return (
      <>
        {this.state.popOvers.map((popOver) => popOver)}
        {editComment ? (
          <div className="mt-10">
            <CommentFormContainer
              comment={comment}
              isEdit={true}
              closeForm={() => this.setState({ editComment: false })}
            />
          </div>
        ) : (
          <div>
            <div className="comment mt-10">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-5">
                  <img
                    className="rounded"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      objectFit: 'cover',
                    }}
                    src={
                      comment?.creator?.avatar && comment?.creator?.user?.avatar?.url
                        ? comment?.creator?.avatar.url
                        : '/images/user.svg'
                    }
                    alt=""
                  />
                  <span className="font-sm">
                    {comment?.creator?.firstName} {comment?.creator?.lastName}
                  </span>
                  <span className="font-sm text-darkgray">
                    {defaultDateFormat(comment?.createdAt, this.props?.dateFormat)}
                  </span>
                </div>
                {comment?.creator?.id === user?.id && (
                  <div className="board-card__actions d-flex gap-10">
                    <div
                      className="circle-icon-primary circle-md svg-md"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (addComment) {
                          closeAddComment();
                        }

                        this.setState({ editComment: true });
                      }}
                    >
                      <ReactSVG src="/icons/edit.svg" />
                    </div>
                    <div
                      className="circle-icon-primary circle-md svg-md"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.setState({
                          popOvers: [
                            ...this.state.popOvers,
                            <PopOver
                              isActive={true}
                              className="select-pop-over"
                              onClose={() => {
                                this.setState({
                                  popOvers: [],
                                });
                              }}
                            >
                              <div className="box">
                                <div className="title">{t('todo.comment.delete.header')}</div>
                                <div className="text">{t('todo.comment.delete.description')}</div>

                                <div className="choice">
                                  <button
                                    href=""
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.setState({
                                        popOvers: [],
                                      });
                                    }}
                                  >
                                    {t('cancel')}
                                  </button>
                                  <div
                                    className="as-link"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.setState({
                                        popOvers: [],
                                      });

                                      deleteComment(comment);
                                    }}
                                  >
                                    {t('delete')}
                                  </div>
                                </div>
                              </div>
                            </PopOver>,
                          ],
                        });
                      }}
                    >
                      <ReactSVG src="/icons/trash.svg" />
                    </div>
                  </div>
                )}
              </div>
              <div
                className="board-card__description text-darkgray font-md mt-10"
                dangerouslySetInnerHTML={{
                  __html: comment?.comment
                    ? draftToHtml(
                        JSON.parse(comment?.comment.replace(/\n/g, '')),
                        null,
                        null,
                        this.mentionEntityToHTML
                      )
                    : '',
                }}
              ></div>
            </div>
            {comment?.documents?.length > 0 && (
              <div className="d-flex gap-20 mt-20" style={{ overflowX: 'scroll' }}>
                <ReactSVG className="attachment-icon" src="/icons/attachment.svg" />
                {comment?.documents.map((document) => {
                  const file = document?.entity?.file;

                  return (
                    <div key={`${file?.url}-${file?.nonce}`}>
                      <div className="name d-flex font-sm align-items-center text-darkgray font-medium">
                        {file.error ? file.error : file.originalName || file.name || file.path}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
